<template>
  <div class="app-icon-block">
    <slot name="icon">
      <img :src="icon">
    </slot>
    <div class="content">
      <slot name="content">
        <p v-html="content"></p>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppIconBlock',
  props: {
    content: {
      type: String,
      required: false
    },
    type: {
      type: String,
      required: false,
      default: 'info',
      validation (value) {
        return ['check', 'info', 'warning'].includes(value)
      }
    }
  },
  computed: {
    icon () {
      const icons = {
        check: require('@/assets/icons/check-circled.svg'),
        info: require('@/assets/icons/info.svg'),
        warning: require('@/assets/icons/warning.svg')
      }
      return icons[this.type]
    }
  }
}
</script>

<style lang="scss" scoped>
.app-icon-block {
  display: flex;
  align-items: flex-start;
  img {
    flex-shrink: 0;
    width: 26px;
    margin-right: $space-sm;
  }
  .content {
    font-family: $ff-text;
    font-weight: $fw-m;
  }
}
</style>
